#error {
    text-align: center;
    color: red;
    margin-bottom: 50px;
}

#logoutbutton {
    margin-bottom: 100px;
}

.flex-container-vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
}